import type DataGridControl from 'o365.controls.DataGrid.ts';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { defineComponent, h, inject, markRaw, onMounted, onBeforeUnmount, ref } from 'vue';
import DataColumn from 'o365.controls.DataGrid.Column.ts';

declare module 'o365.controls.DataGrid.Column.ts' {
    interface DataColumn {
        autoHeightApi: ColumnAutoHeightApi
    }
}

Object.defineProperties(DataColumn.prototype, {
    'autoHeightApi': {
        get() {
            if (this._autoHeightApi == null) {
                this._autoHeightApi = new ColumnAutoHeightApi();
            }
            return this._autoHeightApi;
        }
    },
});

type AutoHeightCellCtx = {
    slots: {
        default: (bindgins: {
            target: (pElement: HTMLElement | null) => void
        })=>void
    },
};

type AutoHeightCellProps = {
    column: DataColumn
};

const AutoHeightCell = markRaw(defineComponent({
    name: 'AutoHeightCell',
    props: {
        column: Object
    },
    setup(props: AutoHeightCellProps, ctx: AutoHeightCellCtx) {
        const dataGridControl: { value?: DataGridControl} = inject(dataGridControlKey);
        if (dataGridControl == null || dataGridControl.value == null) {
            throw new TypeError('AutoHeightCell cannot be used outside of data grid');
        }
        const cellRef: { value: HTMLElement | null } = ref(null);
        const uid = crypto.randomUUID();
        const cellObserver = getAutoHeightCellObserver(uid, dataGridControl.value); 
        onMounted(() => {
            if (cellRef.value) {
                cellObserver.setElement(cellRef.value);
                cellObserver.observe();
            }
        });
        onBeforeUnmount(() => {
            cellObserver.unobserve();
        });
        return () => ctx.slots.default({target: el => cellRef.value = el});
    },
}));

const autoHeightCellObserverStore = new Map<string, AutoHeightCellObserver>();
function getAutoHeightCellObserver(pUid: string, pDataGridControl: DataGridControl) {
    if (!autoHeightCellObserverStore.has(pUid)) {
        autoHeightCellObserverStore.set(pUid, new AutoHeightCellObserver(pDataGridControl));
    }
    return autoHeightCellObserverStore.get(pUid)!;
}

class AutoHeightCellObserver {
    private _dataGridControl: DataGridControl;
    private _element?: HTMLElement;
    private _observer: ResizeObserver;
    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
        this._observer = new ResizeObserver((entries) => {
            console.log(entries);
        });
    }

    setElement(pElement: HTMLElement) {
        this._element = pElement;
    }
    observe() {
        if (this._element == null) { return; }
        return;
        this._observer.observe(this._element);
    }
    unobserve() {
        if (this._element == null) { return; }
        return;
        this._observer.unobserve(this._element);
    }

    destroy() {
        this._observer.disconnect();
    }
}

class ColumnAutoHeightApi {
    getCellComponent() { return AutoHeightCell; }
}